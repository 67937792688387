import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaCheckCircle, FaCheck, FaLock } from 'react-icons/fa';
import ModuleContent from './ModuleContent';
import QuizComponent from './QuizComponent';
import api from '../../utils/api';
import { toast } from 'react-hot-toast';

const CourseDetail = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [completedModules, setCompletedModules] = useState([]);
  const [quizPassed, setQuizPassed] = useState(false);
  const [allModules, setAllModules] = useState([]);

  const fetchCourseDetails = useCallback(async () => {
    try {
      console.log('Fetching course details for courseId:', courseId);
      const response = await api.get(`/courses/${courseId}/details`);
      console.log('Course details response:', response.data);
      console.log('Current module from API:', response.data.currentModule);
      console.log('Completed modules from API:', response.data.completedModules);

      setCourse(response.data);
      setCompletedModules(response.data.completedModules || []);
      setQuizPassed(response.data.quizPassed || false);
      setProgress(response.data.progress || 0);

      // Find the current module from the response
      if (response.data.currentModule === 'quiz') {
        console.log('Current module is quiz, setting quiz module');
        setSelectedModule({
          _id: 'quiz',
          title: 'Final Assessment',
          description: 'Complete this quiz with a score of 80% or higher to finish the course.',
          duration: '30',
          type: 'quiz',
        });
      } else if (response.data.currentModule) {
        console.log('Looking for current module with ID:', response.data.currentModule);
        const currentModule = response.data.modules.find(
          m => m._id === response.data.currentModule
        );
        console.log('Found current module:', currentModule);
        if (currentModule) {
          console.log('Setting selected module to:', currentModule);
          setSelectedModule(currentModule);
        } else {
          console.log('Current module not found in modules list');
        }
      } else {
        // If no current module, find the first incomplete module
        console.log('No current module, finding first incomplete module');
        const firstIncompleteModule = response.data.modules.find(
          module => !response.data.completedModules?.includes(module._id)
        );
        console.log('First incomplete module:', firstIncompleteModule);
        setSelectedModule(firstIncompleteModule || response.data.modules[0]);
      }

      setLoading(false);
      return response.data; // Return the course data
    } catch (error) {
      console.error('Error fetching course details:', error);
      setLoading(false);
      return null;
    }
  }, [courseId]);

  const calculateProgress = useCallback(() => {
    if (!course || !course.modules || course.modules.length === 0) return 0;

    const totalItems = course.hasQuiz ? course.modules.length + 1 : course.modules.length;
    const completedItems = completedModules.length + (quizPassed ? 1 : 0);
    return Math.round((completedItems / totalItems) * 100);
  }, [course, completedModules, quizPassed]);

  useEffect(() => {
    fetchCourseDetails();
  }, [fetchCourseDetails]);

  useEffect(() => {
    if (course) {
      const newProgress = calculateProgress();
      setProgress(newProgress);
    }
  }, [course, calculateProgress]);

  useEffect(() => {
    if (course) {
      const modules = [
        ...course.modules,
        ...(course.hasQuiz
          ? [
              {
                _id: 'quiz',
                title: 'Final Assessment',
                description:
                  'Complete this quiz with a score of 80% or higher to finish the course.',
                duration: '30',
                type: 'quiz',
              },
            ]
          : []),
      ];
      setAllModules(modules);
    }
  }, [course]);

  const isModuleAvailable = useCallback(
    moduleIndex => {
      if (!course || !allModules.length) return false;

      // Quiz is available if all modules are completed
      if (moduleIndex === allModules.length - 1 && course?.hasQuiz) {
        return course.modules.every(m => completedModules.includes(m._id));
      }

      // First module is always available
      if (moduleIndex === 0) return true;

      // A module is available if the previous module is completed
      const previousModuleId = allModules[moduleIndex - 1]._id;
      return completedModules.includes(previousModuleId);
    },
    [allModules, completedModules, course]
  );

  const isCourseCompleted = useCallback(() => {
    if (!course) return false;

    // If course has quiz, require both modules and quiz completion
    if (course.hasQuiz) {
      return completedModules.length === course.modules.length && quizPassed;
    }

    // If no quiz, only require module completion
    return completedModules.length === course.modules.length;
  }, [course, completedModules, quizPassed]);

  const handleModuleComplete = async moduleId => {
    try {
      console.log('Starting module completion for moduleId:', moduleId);
      console.log('Course state:', {
        courseId,
        courseModules: course?.modules,
        currentModuleId: selectedModule?._id,
        completedModules,
      });

      const completeResponse = await api.post(`/courses/${courseId}/modules/${moduleId}/complete`);
      console.log('Module completion API response:', completeResponse.data);

      if (completeResponse.data.success) {
        // Update local state
        const newCompletedModules = completeResponse.data.completedModules;
        setCompletedModules(newCompletedModules);

        // Find current module index
        const currentModuleIndex = course.modules.findIndex(m => m._id === moduleId);
        console.log('Module progression:', {
          currentModuleIndex,
          totalModules: course.modules.length,
          nextModuleIndex: currentModuleIndex + 1,
        });

        let nextModule;
        // Determine next module or quiz
        if (currentModuleIndex === course.modules.length - 1 && course.hasQuiz) {
          console.log('Transitioning to quiz after last module');
          nextModule = {
            _id: 'quiz',
            title: 'Final Assessment',
            description: 'Complete this quiz with a score of 80% or higher to finish the course.',
            duration: '30',
            type: 'quiz',
          };
        } else if (currentModuleIndex < course.modules.length - 1) {
          console.log('Advancing to next module');
          nextModule = course.modules[currentModuleIndex + 1];
          console.log('Next module:', nextModule);
        }

        if (nextModule) {
          console.log('Setting next module:', nextModule);
          setSelectedModule(nextModule);

          // Update the current module in the backend
          try {
            await api.post(`/courses/${courseId}/progress`, {
              currentModule: nextModule._id,
              completedModules: newCompletedModules,
            });
          } catch (error) {
            console.error('Error updating current module:', error);
          }
        }

        // Scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Update course details to get the latest progress
        const updatedCourse = await fetchCourseDetails();
        console.log('Updated course details:', updatedCourse);

        // Update progress based on the latest data
        const newProgress = calculateProgress();
        setProgress(newProgress);
      } else {
        console.warn('Module completion API returned success: false');
      }
    } catch (error) {
      console.error('Error in handleModuleComplete:', error);
      toast.error('Failed to complete module. Please try again.');
    }
  };

  const handleQuizComplete = async passed => {
    if (passed) {
      setQuizPassed(true);
      // Refresh course details to get updated progress
      await fetchCourseDetails();
      // No need to recalculate progress here as fetchCourseDetails will set it correctly
    }
  };

  // Add useEffect to log state changes
  useEffect(() => {
    console.log('State updated:', {
      selectedModule,
      completedModules,
      progress,
      quizPassed,
    });
  }, [selectedModule, completedModules, progress, quizPassed]);

  if (loading) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
      </div>
    );
  }

  if (!course) {
    return <div className='p-4 bg-yellow-50 text-yellow-600 rounded-md'>Course not found</div>;
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      {/* Back to Dashboard Link */}
      <div className='mb-6'>
        <Link
          to='/dashboard'
          className='inline-flex items-center text-gray-600 hover:text-gray-900'
        >
          <FaArrowLeft className='mr-2' />
          Back to Dashboard
        </Link>
      </div>

      {/* Course Header */}
      <div className='bg-white rounded-lg shadow-sm p-6 mb-6'>
        <div className='flex flex-col space-y-4'>
          <div className='flex flex-col md:flex-row md:items-center justify-between'>
            <h1 className='text-2xl font-bold text-gray-900 mb-2 md:mb-0'>{course?.title}</h1>
            <div className='min-w-[200px] bg-gray-50 rounded-lg p-3 flex items-center justify-center'>
              {isCourseCompleted() ? (
                <span className='inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800'>
                  <FaCheckCircle className='mr-2 h-4 w-4' />
                  Course Completed
                </span>
              ) : (
                <div className='flex items-center'>
                  <span className='hidden md:inline text-sm text-gray-600 mr-2'>
                    Course Completion:
                  </span>
                  <span className='text-lg font-semibold text-primary-600'>
                    {Math.round(progress)}%
                  </span>
                </div>
              )}
            </div>
          </div>
          <p className='text-gray-600'>{course?.description}</p>
        </div>
      </div>

      {/* Two Column Layout */}
      <div className='flex flex-col lg:flex-row gap-6'>
        {/* Module List - Left Column */}
        <div className='lg:w-1/3'>
          <div className='bg-white rounded-lg shadow-sm'>
            <div className='p-6 border-b border-gray-200'>
              <h2 className='text-xl font-semibold text-gray-900'>Modules</h2>
            </div>
            <div className='divide-y divide-gray-200'>
              {allModules.map((module, index) => {
                const isCompleted =
                  module.type === 'quiz' ? quizPassed : completedModules.includes(module._id);
                const isAvailable = isModuleAvailable(index);
                const isSelected = selectedModule && selectedModule._id === module._id;

                console.log('Module list item:', {
                  moduleId: module._id,
                  isCompleted,
                  isAvailable,
                  isSelected,
                  selectedModuleId: selectedModule?._id,
                  completedModules,
                  moduleType: module.type,
                  quizPassed,
                });

                return (
                  <button
                    key={module._id}
                    onClick={() => (isAvailable || isCompleted ? setSelectedModule(module) : null)}
                    disabled={!isAvailable && !isCompleted}
                    className={`w-full text-left p-4 md:p-6 transition-colors ${
                      isSelected
                        ? 'bg-primary-50 border-l-4 border-primary-500'
                        : isCompleted
                          ? 'bg-green-50 hover:bg-green-100'
                          : isAvailable
                            ? 'hover:bg-gray-50'
                            : 'opacity-50 cursor-not-allowed bg-gray-50'
                    }`}
                  >
                    <div className='flex items-start justify-between'>
                      <div className='flex-1 min-w-0'>
                        <div className='flex items-center gap-2'>
                          <h3 className='text-base leading-5 md:text-lg md:leading-7 font-medium text-gray-900'>
                            {module.type === 'quiz'
                              ? module.title
                              : `Module ${index + 1}: ${module.title}`}
                          </h3>
                          {!isAvailable && !isCompleted && (
                            <FaLock className='text-gray-400 h-4 w-4' />
                          )}
                        </div>
                      </div>
                      {isCompleted ? (
                        <span className='ml-4 flex-shrink-0'>
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800'>
                            <FaCheck className='mr-1.5 h-4 w-4' />
                            Completed
                          </span>
                        </span>
                      ) : isSelected ? (
                        <span className='ml-4 flex-shrink-0'>
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-primary-100 text-primary-800'>
                            Current
                          </span>
                        </span>
                      ) : isAvailable ? (
                        <span className='ml-4 flex-shrink-0'>
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800'>
                            Available
                          </span>
                        </span>
                      ) : (
                        <span className='ml-4 flex-shrink-0'>
                          <span className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800'>
                            Locked
                          </span>
                        </span>
                      )}
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Module Content - Right Column */}
        <div className='lg:flex-1'>
          {selectedModule && (
            <div className='bg-white rounded-lg shadow-sm'>
              {selectedModule.type !== 'quiz' && (
                <div className='px-6 py-3 border-b border-gray-200'>
                  <h3 className='text-sm font-medium text-gray-900'>
                    Module {allModules.findIndex(m => m._id === selectedModule._id) + 1}:{' '}
                    {selectedModule.title}
                  </h3>
                </div>
              )}

              <div className='p-6'>
                {selectedModule.type === 'quiz' ? (
                  quizPassed ? (
                    <div className='text-center py-8'>
                      <FaCheckCircle className='mx-auto h-16 w-16 text-green-500 mb-4' />
                      <h2 className='text-2xl font-bold text-gray-900 mb-2'>Congratulations!</h2>
                      <p className='text-lg text-gray-600'>
                        You have successfully passed this course&apos;s assessment.
                      </p>
                    </div>
                  ) : (
                    <QuizComponent courseId={courseId} onComplete={handleQuizComplete} />
                  )
                ) : (
                  <ModuleContent
                    module={selectedModule}
                    onComplete={() => handleModuleComplete(selectedModule._id)}
                    isCompleted={completedModules.includes(selectedModule._id)}
                    courseName={course?.title}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
