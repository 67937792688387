import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import { FaExclamationTriangle } from 'react-icons/fa';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby='delete-modal-title'>
      <div className='fixed inset-0 flex items-center justify-center'>
        <div className='bg-white rounded-lg p-6 max-w-md mx-4 relative z-50'>
          <div className='flex items-center mb-4'>
            <div className='flex-shrink-0 bg-red-100 rounded-full p-3 mr-4'>
              <FaExclamationTriangle className='text-red-600 text-xl' />
            </div>
            <h2 id='delete-modal-title' className='text-lg font-medium text-gray-900'>
              {title}
            </h2>
          </div>

          <p className='text-sm text-gray-500 mb-6'>{message}</p>

          <div className='flex justify-end space-x-4'>
            <button
              onClick={onClose}
              className='px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className='px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            >
              Delete
            </button>
          </div>
        </div>

        {/* Backdrop */}
        <div
          className='fixed inset-0 bg-black bg-opacity-50'
          onClick={onClose}
          aria-hidden='true'
        />
      </div>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default DeleteConfirmationModal;
