import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ url, onComplete, title }) => {
  const [isYouTube, setIsYouTube] = useState(false);
  const [videoId, setVideoId] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    // Check if URL is a YouTube URL and extract video ID
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(youtubeRegex);

    if (match && match[1]) {
      setIsYouTube(true);
      setVideoId(match[1]);
    } else {
      setIsYouTube(false);
    }
  }, [url]);

  const handleVideoEnd = () => {
    if (onComplete) {
      onComplete();
    }
  };

  const handleKeyPress = e => {
    if (!videoRef.current) return;

    switch (e.key) {
      case ' ':
      case 'k':
        e.preventDefault();
        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
        break;
      case 'ArrowLeft':
        e.preventDefault();
        videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 5);
        break;
      case 'ArrowRight':
        e.preventDefault();
        videoRef.current.currentTime = Math.min(
          videoRef.current.duration,
          videoRef.current.currentTime + 5
        );
        break;
      case 'm':
        e.preventDefault();
        videoRef.current.muted = !videoRef.current.muted;
        break;
      case 'f':
        e.preventDefault();
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          videoRef.current.requestFullscreen();
        }
        break;
      default:
        break;
    }
  };

  if (isYouTube) {
    return (
      <div
        className='aspect-w-16 aspect-h-9'
        role='region'
        aria-label={title || 'YouTube video player'}
      >
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
          title={title || 'YouTube video player'}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          className='w-full h-full'
          onLoad={e => {
            e.target.addEventListener('ended', handleVideoEnd);
          }}
          aria-label={title || 'YouTube video player'}
        ></iframe>
      </div>
    );
  }

  return (
    <div className='aspect-w-16 aspect-h-9' role='region' aria-label={title || 'Video player'}>
      <video
        ref={videoRef}
        controls
        className='w-full h-full'
        onEnded={handleVideoEnd}
        onKeyDown={handleKeyPress}
        tabIndex='0'
        aria-label={title || 'Video player'}
      >
        <source src={url} type='video/mp4' />
        <track kind='captions' src='' label='English captions' srcLang='en' default />
        <p>
          Your browser doesn&apos;t support HTML5 video. Here is a{' '}
          <a href={url}>link to the video</a> instead.
        </p>
      </video>
      <div className='sr-only'>
        <p>Video keyboard controls:</p>
        <ul>
          <li>Space or K: Play/Pause</li>
          <li>Left Arrow: Rewind 5 seconds</li>
          <li>Right Arrow: Forward 5 seconds</li>
          <li>M: Mute/Unmute</li>
          <li>F: Toggle fullscreen</li>
        </ul>
      </div>
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  title: PropTypes.string,
};

export default VideoPlayer;
