import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../utils/api';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { toast } from 'react-hot-toast';

const QuizComponent = ({ courseId, onComplete }) => {
  const [quiz, setQuiz] = useState(null);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await api.get(`/courses/${courseId}/quiz`);
        console.log('Quiz data:', response.data);
        setQuiz(response.data);
        setLoading(false);
        // Scroll to top when quiz loads
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        console.error('Error fetching quiz:', error);
        if (error.response?.data?.status === 'NO_QUESTIONS') {
          setError('This quiz has not been configured yet. Please contact your instructor.');
        } else if (error.response?.data?.status === 'NO_QUIZ') {
          setError('No quiz is available for this course.');
        } else {
          setError(error.response?.data?.error || 'Failed to load quiz');
        }
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [courseId]);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);

    try {
      console.log('Submitting quiz answers:', answers);
      console.log('Course ID:', courseId);

      const formattedAnswers = Object.entries(answers).map(([questionId, selectedOption]) => ({
        questionId,
        selectedOption,
      }));

      console.log('Formatted answers:', formattedAnswers);

      const response = await api.post(`/courses/${courseId}/quiz/submit`, {
        answers: formattedAnswers,
      });

      console.log('Quiz submission response:', response.data);

      if (response.data.passed) {
        toast.success('Congratulations! You passed the quiz!');
        onComplete(true);
      } else {
        toast.error(`You scored ${response.data.score}%. Required: 80% to pass.`);
        onComplete(false);
      }
    } catch (error) {
      console.error('Error submitting quiz:', error);
      console.error('Error details:', error.response?.data);
      toast.error(error.response?.data?.error || 'Failed to submit quiz. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!quiz || !quiz.questions || quiz.questions.length === 0) {
    return (
      <Alert severity='info'>
        No quiz questions are available. Please contact your instructor.
      </Alert>
    );
  }

  return (
    <Box component='form' onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant='h5' gutterBottom>
        Course Quiz
      </Typography>
      <Typography variant='body2' color='text.secondary' gutterBottom>
        Passing score: {quiz.passingScore}%
      </Typography>

      {quiz.questions.map((question, index) => (
        <Box
          key={question._id}
          sx={{ mb: 4, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}
        >
          <Typography variant='h6' gutterBottom>
            Question {index + 1}
          </Typography>
          <Typography gutterBottom>{question.text}</Typography>

          <RadioGroup
            value={answers[question._id] || ''}
            onChange={e => setAnswers({ ...answers, [question._id]: e.target.value })}
          >
            {question.options.map(option => (
              <FormControlLabel
                key={option._id}
                value={option._id}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
        </Box>
      ))}

      <Button
        type='submit'
        variant='contained'
        color='primary'
        disabled={submitting || Object.keys(answers).length !== quiz.questions.length}
        sx={{ mt: 2 }}
      >
        {submitting ? <CircularProgress size={24} /> : 'Submit Quiz'}
      </Button>
    </Box>
  );
};

QuizComponent.propTypes = {
  courseId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default QuizComponent;
