import React, { useState, useEffect } from 'react';
import { messageApi } from '../../api/messageApi';
import { userApi } from '../../api/userApi';
import { toast } from 'react-toastify';
import FormLabel from '../Common/FormLabel';
import PropTypes from 'prop-types';

const NewThreadModal = ({ isOpen, onClose, onSuccess }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await userApi.getAllUsers();
        console.log('Available users:', response.data);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to load users');
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!selectedUser || !subject.trim() || !message.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    console.log('Sending message with data:', {
      recipient: selectedUser,
      subject: subject.trim(),
      message: message.trim(),
    });

    try {
      const response = await messageApi.createThread({
        recipient: selectedUser,
        subject: subject.trim(),
        message: message.trim(),
      });
      console.log('Message sent successfully:', response);
      toast.success('Message sent successfully');
      onSuccess();
      onClose();
      // Reset form
      setSelectedUser('');
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Error creating thread:', error);
      console.error('Error details:', error.response?.data);
      toast.error('Failed to send message');
    }
  };

  const handleBackdropClick = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center'>
        <div
          onClick={handleBackdropClick}
          aria-hidden='true'
          role='presentation'
          className='fixed inset-0 bg-black bg-opacity-50'
        ></div>

        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
          <form onSubmit={handleSubmit} className='p-6'>
            <h3 className='text-lg font-medium text-gray-900 mb-4'>New Message</h3>

            <div className='mb-4'>
              <FormLabel htmlFor='recipient' required>
                Recipient
              </FormLabel>
              <select
                id='recipient'
                value={selectedUser}
                onChange={e => {
                  console.log('Selected user:', e.target.value);
                  setSelectedUser(e.target.value);
                }}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
                required
                disabled={loading}
              >
                <option value=''>Select a recipient</option>
                {Array.isArray(users) && users.length > 0 ? (
                  users.map(user => (
                    <option key={user._id} value={user._id}>
                      {user.firstName || user.name} {user.lastName} ({user.email})
                    </option>
                  ))
                ) : (
                  <option value='' disabled>
                    {loading ? 'Loading users...' : 'No users available'}
                  </option>
                )}
              </select>
            </div>

            <div className='mb-4'>
              <FormLabel htmlFor='subject' required>
                Subject
              </FormLabel>
              <input
                type='text'
                id='subject'
                value={subject}
                onChange={e => setSubject(e.target.value)}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
                required
              />
            </div>

            <div className='mb-4'>
              <FormLabel htmlFor='message' required>
                Message
              </FormLabel>
              <textarea
                id='message'
                value={message}
                onChange={e => setMessage(e.target.value)}
                rows={4}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
                required
              />
            </div>

            <div className='flex justify-end gap-3'>
              <button
                type='button'
                onClick={onClose}
                className='px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md'
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NewThreadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default NewThreadModal;
