import React, { useState, useEffect } from 'react';
import { userApi } from '../../api/userApi';
import UserForm from './UserForm';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [formMode, setFormMode] = useState('create');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await userApi.getAllUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSubmit = async userData => {
    try {
      if (selectedUser) {
        await userApi.updateUser(selectedUser._id, userData);
      } else {
        await userApi.createUser(userData);
      }
      await fetchUsers();
      setShowForm(false);
      setSelectedUser(null);
    } catch (error) {
      console.error('Error saving user:', error);
      throw error;
    }
  };

  const handleEdit = user => {
    console.log('Editing user:', user); // Debug log
    setSelectedUser({
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      userType: user.userType,
      active: user.active,
      password: '', // Clear password for editing
    });
    setFormMode('edit');
    setShowForm(true);
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setFormMode('create');
    setShowForm(true);
  };

  const handleDeleteClick = user => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await userApi.deleteUser(userToDelete._id);
      setUsers(users.filter(user => user._id !== userToDelete._id));
      setShowDeleteModal(false);
      setUserToDelete(null);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-2xl font-semibold text-gray-900'>Users</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={handleAddUser}
            className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
          >
            <FaPlus className='mr-2' /> Add User
          </button>
        </div>
      </div>

      <div className='mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <div className='relative'>
          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Role
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    User Type
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Status
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <span className='sr-only'>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {users.map(user => (
                  <tr key={user._id} className='hover:bg-gray-50'>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                      {user.firstName} {user.lastName}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {user.email}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {user.role}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {user.userType}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <span
                        className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                          user.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {user.active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className='sticky right-0 z-10 px-6 py-4 whitespace-nowrap text-sm font-medium'>
                      <div className='flex justify-end items-center md:bg-transparent bg-white md:shadow-none shadow-md md:rounded-none rounded-l-md pl-4'>
                        <button
                          onClick={() => handleEdit(user)}
                          className='text-primary-600 hover:text-primary-900 p-2'
                          title='Edit User'
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(user)}
                          className='text-red-600 hover:text-red-900 p-2 ml-2'
                          title='Delete User'
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showForm && (
        <UserForm
          onClose={() => {
            setShowForm(false);
            setSelectedUser(null);
          }}
          onSubmit={handleSubmit}
          initialData={selectedUser}
          mode={formMode}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setUserToDelete(null);
          }}
          onConfirm={handleDeleteConfirm}
          title='Delete User'
          message={`Are you sure you want to delete ${userToDelete?.firstName} ${userToDelete?.lastName}? This action cannot be undone.`}
        />
      )}
    </div>
  );
};

export default UserManagement;
