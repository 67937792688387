import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '../Common/FormLabel';
import USER_GROUPS from '../../constants/userGroups';

const UserForm = ({ mode, initialData, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: initialData?.firstName || '',
    lastName: initialData?.lastName || '',
    email: initialData?.email || '',
    password: '',
    role: initialData?.role || 'user',
    userType: initialData?.userType || '',
    active: initialData?.active ?? true,
  });

  // Reset form when initialData changes (for edit mode)
  useEffect(() => {
    if (initialData) {
      setFormData({
        firstName: initialData.firstName || '',
        lastName: initialData.lastName || '',
        email: initialData.email || '',
        password: '',
        role: initialData.role || 'user',
        userType: initialData.userType || '',
        active: initialData.active ?? true,
      });
    }
  }, [initialData]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      };

      // Clear userType if role is changed to admin
      if (name === 'role' && value === 'admin') {
        newData.userType = '';
      }

      return newData;
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Only include password in submission if it's not empty
    const submitData = { ...formData };
    if (!submitData.password) {
      delete submitData.password;
    }
    onSubmit(submitData);
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full'>
      <div className='relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white'>
        <div className='mt-3'>
          <h2 className='text-2xl font-bold mb-6'>
            {mode === 'create' ? 'Create' : 'Update'} User
          </h2>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <FormLabel htmlFor='firstName' required>
                First Name
              </FormLabel>
              <input
                id='firstName'
                type='text'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                className='w-full p-2 border rounded'
                required
              />
            </div>

            <div className='mb-4'>
              <FormLabel htmlFor='lastName' required>
                Last Name
              </FormLabel>
              <input
                id='lastName'
                type='text'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                className='w-full p-2 border rounded'
                required
              />
            </div>

            <div className='mb-4'>
              <FormLabel htmlFor='email' required>
                Email
              </FormLabel>
              <input
                id='email'
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                className='w-full p-2 border rounded'
                required
              />
            </div>

            {mode === 'create' && (
              <div className='mb-4'>
                <FormLabel htmlFor='password' required>
                  Password
                </FormLabel>
                <input
                  id='password'
                  type='password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  required={mode === 'create'}
                  minLength={8}
                />
              </div>
            )}

            <div className='mb-4'>
              <FormLabel htmlFor='role' required>
                Role
              </FormLabel>
              <select
                id='role'
                name='role'
                value={formData.role}
                onChange={handleChange}
                className='w-full p-2 border rounded'
                required
              >
                <option value='user'>User</option>
                <option value='admin'>Admin</option>
              </select>
            </div>

            {formData.role === 'user' && (
              <div className='mb-4'>
                <FormLabel htmlFor='userType' required>
                  User Group
                </FormLabel>
                <select
                  id='userType'
                  name='userType'
                  value={formData.userType}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  required={formData.role === 'user'}
                >
                  <option value=''>Select User Group</option>
                  {USER_GROUPS.map(group => (
                    <option key={group} value={group}>
                      {group
                        .split('-')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className='mb-6'>
              <div className='flex items-center'>
                <input
                  id='active'
                  type='checkbox'
                  name='active'
                  checked={formData.active}
                  onChange={handleChange}
                  className='h-4 w-4 text-blue-600 border-gray-300 rounded'
                />
                <FormLabel htmlFor='active' className='ml-2'>
                  Active
                </FormLabel>
              </div>
            </div>

            <div className='flex justify-end gap-4'>
              <button
                type='button'
                onClick={onClose}
                className='px-4 py-2 text-gray-600 border rounded hover:bg-gray-100'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700'
              >
                {mode === 'create' ? 'Create' : 'Update'} User
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  initialData: null,
};

export default UserForm;
