import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, Button } from '@mui/material';
import { messageApi } from '../../api/messageApi';

const MessageModal = ({ isOpen, onClose, defaultSubject, readOnlySubject, metadata }) => {
  const [subject, setSubject] = useState(defaultSubject || '');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      setSending(true);
      await messageApi.createThread({
        subject,
        message,
        recipient: import.meta.env.VITE_ADMIN_USER_ID,
        metadata,
      });
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby='message-modal-title'>
      <div style={modalStyle}>
        <div className='bg-white p-6 rounded-lg'>
          <h2 id='message-modal-title' className='text-xl font-semibold text-gray-900 mb-4'>
            Ask a Question
          </h2>
          <form onSubmit={handleSubmit} className='space-y-4'>
            {!readOnlySubject ? (
              <TextField
                label='Subject'
                value={subject}
                onChange={e => setSubject(e.target.value)}
                fullWidth
                required
                variant='outlined'
                className='mb-4'
              />
            ) : (
              <TextField
                label='Subject'
                value={subject}
                fullWidth
                variant='outlined'
                disabled
                className='mb-4'
              />
            )}

            <TextField
              label='Message'
              value={message}
              onChange={e => setMessage(e.target.value)}
              multiline
              rows={4}
              fullWidth
              required
              variant='outlined'
              className='mb-4'
              placeholder='Type your question here...'
            />

            <div className='flex justify-end gap-3 mt-6'>
              <Button
                onClick={onClose}
                variant='outlined'
                className='text-gray-700 bg-gray-100 hover:bg-gray-200'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={sending}
                className='bg-primary-600 hover:bg-primary-700'
              >
                {sending ? 'Sending...' : 'Send Question'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

MessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultSubject: PropTypes.string,
  readOnlySubject: PropTypes.bool,
  metadata: PropTypes.object,
};

MessageModal.defaultProps = {
  defaultSubject: '',
  readOnlySubject: false,
  metadata: null,
};

export default MessageModal;
