import api from './axiosConfig';

export const userApi = {
  getAllUsers: () => api.get('/users'),
  getUsers: () => api.get('/users'),

  createUser: userData => api.post('/users', userData),

  updateUser: (userId, data) => api.put(`/users/${userId}`, data),

  deleteUser: userId => api.delete(`/users/${userId}`),

  getUserProfile: () => api.get('/users/profile'),

  updateUserProfile: data => api.put('/users/profile', data),
};
