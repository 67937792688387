export const userGroupOptions = [
  { value: 'business-development', label: 'Business Development' },
  { value: 'referral-partner', label: 'Referral Partner' },
  { value: 'channel-partner', label: 'Channel Partner' },
  { value: 'agency', label: 'Agency' },
];

export const userGroupLabels = {
  'business-development': 'Business Development',
  'referral-partner': 'Referral Partner',
  'channel-partner': 'Channel Partner',
  agency: 'Agency',
};
