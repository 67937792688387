import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ModuleEditor from './ModuleEditor';
import { courseApi } from '../../api/courseApi';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userGroupOptions } from '../../utils/constants';
import FormLabel from '../Common/FormLabel';

const CourseForm = ({ course }) => {
  const navigate = useNavigate();
  const { courseId } = useParams(); // Get courseId from URL
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    modules: [],
    status: 'draft',
    userGroups: [],
    content: '',
    hasQuiz: false,
    quiz: {
      questions: [],
      passingScore: 80,
      enabled: true,
    },
  });
  const [isUploading, setIsUploading] = useState(false);

  // Fetch course data if editing
  useEffect(() => {
    const fetchCourse = async () => {
      if (courseId) {
        try {
          const response = await courseApi.getCourse(courseId);
          setFormData({
            title: response.data.title || '',
            description: response.data.description || '',
            modules: response.data.modules || [],
            status: response.data.status || 'draft',
            userGroups: response.data.userGroups || [],
            content: response.data.content || '',
            hasQuiz: response.data.hasQuiz || false,
            quiz: response.data.quiz || {
              questions: [],
              passingScore: 80,
              enabled: true,
            },
          });
        } catch (error) {
          console.error('Error fetching course:', error);
          toast.error('Failed to load course data');
        }
      }
      setInitialLoading(false);
    };

    fetchCourse();
  }, [courseId]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserGroupChange = groupValue => {
    setFormData(prev => ({
      ...prev,
      userGroups: prev.userGroups.includes(groupValue)
        ? prev.userGroups.filter(g => g !== groupValue)
        : [...prev.userGroups, groupValue],
    }));
  };

  const handleAddModule = () => {
    setFormData(prev => ({
      ...prev,
      modules: [
        ...prev.modules,
        {
          title: '',
          description: '',
          contentType: 'wysiwyg',
          content: '',
          duration: 30,
          order: prev.modules.length,
        },
      ],
    }));
  };

  const handleModuleUpdate = (index, updatedModule, isCurrentlyUploading = false) => {
    setIsUploading(isCurrentlyUploading);
    setFormData(prev => ({
      ...prev,
      modules: prev.modules.map((module, i) => (i === index ? updatedModule : module)),
    }));
  };

  const handleModuleDelete = index => {
    setFormData(prev => ({
      ...prev,
      modules: prev.modules.filter((_, i) => i !== index),
    }));
  };

  const handleDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(formData.modules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData(prev => ({
      ...prev,
      modules: items.map((item, index) => ({
        ...item,
        order: index,
      })),
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (formData.userGroups.length === 0) {
      toast.error('Please select at least one user group');
      return;
    }

    try {
      setLoading(true);
      if (courseId) {
        await courseApi.updateCourse(courseId, formData);
        toast.success('Course updated successfully');
      } else {
        await courseApi.createCourse(formData);
        toast.success('Course created successfully');
      }
      navigate('/admin/courses');
    } catch (error) {
      console.error('Error saving course:', error);
      toast.error(error.response?.data?.error || 'Failed to save course');
    } finally {
      setLoading(false);
    }
  };

  const handleQuizChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      quiz: {
        ...prev.quiz,
        [field]: value,
      },
    }));
  };

  const addQuestion = () => {
    setFormData(prev => ({
      ...prev,
      quiz: {
        ...prev.quiz,
        questions: [
          ...prev.quiz.questions,
          {
            text: '',
            options: [
              { text: '', isCorrect: false },
              { text: '', isCorrect: false },
            ],
          },
        ],
      },
    }));
  };

  const updateQuestion = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      quiz: {
        ...prev.quiz,
        questions: prev.quiz.questions.map((q, i) => (i === index ? { ...q, [field]: value } : q)),
      },
    }));
  };

  const addOption = questionIndex => {
    setFormData(prev => ({
      ...prev,
      quiz: {
        ...prev.quiz,
        questions: prev.quiz.questions.map((q, i) =>
          i === questionIndex
            ? {
                ...q,
                options: [...q.options, { text: '', isCorrect: false }],
              }
            : q
        ),
      },
    }));
  };

  const updateOption = (questionIndex, optionIndex, field, value) => {
    setFormData(prev => ({
      ...prev,
      quiz: {
        ...prev.quiz,
        questions: prev.quiz.questions.map((q, qI) =>
          qI === questionIndex
            ? {
                ...q,
                options: q.options.map((opt, oI) =>
                  oI === optionIndex
                    ? { ...opt, [field]: field === 'isCorrect' ? value : value.trim() }
                    : field === 'isCorrect'
                      ? { ...opt, isCorrect: false }
                      : opt
                ),
              }
            : q
        ),
      },
    }));
  };

  if (initialLoading) {
    return (
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
        <div className='flex justify-center items-center min-h-[400px]'>
          <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='bg-white rounded-lg shadow-sm p-6'>
        <h1 className='text-2xl font-bold text-gray-900 mb-6'>
          {courseId ? 'Edit Course' : 'Create Course'}
        </h1>

        <form onSubmit={handleSubmit} className='space-y-6'>
          <div>
            <FormLabel htmlFor='title' required>
              Title
            </FormLabel>
            <input
              id='title'
              type='text'
              name='title'
              value={formData.title}
              onChange={handleChange}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
              required
            />
          </div>

          <div>
            <FormLabel htmlFor='description' required>
              Description
            </FormLabel>
            <textarea
              id='description'
              name='description'
              value={formData.description}
              onChange={handleChange}
              rows={4}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
              required
            />
          </div>

          <div>
            <FormLabel htmlFor='status'>Status</FormLabel>
            <select
              id='status'
              name='status'
              value={formData.status}
              onChange={handleChange}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
            >
              <option value='draft'>Draft</option>
              <option value='published'>Published</option>
            </select>
          </div>

          <div className='mb-6'>
            <FormLabel htmlFor='userGroups'>Available To</FormLabel>
            <div className='space-y-2 bg-gray-50 p-4 rounded-md'>
              {userGroupOptions.map(option => (
                <label key={option.value} className='flex items-center'>
                  <input
                    type='checkbox'
                    id={`userGroup-${option.value}`}
                    checked={formData.userGroups?.includes(option.value)}
                    onChange={() => handleUserGroupChange(option.value)}
                    className='h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded'
                  />
                  <span className='ml-2 text-gray-700'>{option.label}</span>
                </label>
              ))}
            </div>
            {formData.userGroups?.length === 0 && (
              <p className='mt-2 text-sm text-red-600'>Please select at least one user group</p>
            )}
          </div>

          <div className='mt-6'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-lg font-medium text-gray-900'>Modules</h3>
              <button
                type='button'
                onClick={handleAddModule}
                className='flex items-center px-3 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700'
              >
                <FaPlus className='mr-2' /> Add Module
              </button>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='modules'>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-4'>
                    {formData.modules.map((module, index) => (
                      <ModuleEditor
                        key={module._id || index}
                        module={module}
                        index={index}
                        onUpdate={updatedModule => handleModuleUpdate(index, updatedModule)}
                        onDelete={() => handleModuleDelete(index)}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className='border-t border-gray-200 pt-6'>
            <div className='flex items-center mb-4'>
              <input
                type='checkbox'
                id='hasQuiz'
                checked={formData.hasQuiz}
                onChange={e => setFormData(prev => ({ ...prev, hasQuiz: e.target.checked }))}
                className='h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded'
              />
              <FormLabel htmlFor='hasQuiz'>Enable Course Quiz</FormLabel>
            </div>

            {formData.hasQuiz && (
              <div className='space-y-4'>
                <div>
                  <label
                    htmlFor='passingScore'
                    className='block text-sm font-medium text-gray-700 mb-1'
                  >
                    Passing Score (%)
                  </label>
                  <input
                    id='passingScore'
                    type='number'
                    value={formData.quiz.passingScore}
                    onChange={e => handleQuizChange('passingScore', parseInt(e.target.value))}
                    className='w-full px-3 py-2 border border-gray-300 rounded-md'
                    min='1'
                    max='100'
                  />
                </div>

                <div className='space-y-4'>
                  <div className='flex justify-between items-center'>
                    <h3 className='text-lg font-medium text-gray-900'>Quiz Questions</h3>
                    <button
                      type='button'
                      onClick={addQuestion}
                      className='px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700'
                    >
                      Add Question
                    </button>
                  </div>

                  {formData.quiz.questions.map((question, qIndex) => (
                    <div key={qIndex} className='border border-gray-200 rounded-md p-4'>
                      <input
                        type='text'
                        value={question.text}
                        onChange={e => updateQuestion(qIndex, 'text', e.target.value)}
                        className='w-full px-3 py-2 border border-gray-300 rounded-md mb-4'
                        placeholder='Question text'
                      />

                      <div className='space-y-2'>
                        {question.options.map((option, oIndex) => (
                          <div key={oIndex} className='flex items-center gap-2'>
                            <input
                              type='text'
                              value={option.text}
                              onChange={e => updateOption(qIndex, oIndex, 'text', e.target.value)}
                              className='flex-1 px-3 py-2 border border-gray-300 rounded-md'
                              placeholder={`Option ${oIndex + 1}`}
                            />
                            <label className='flex items-center'>
                              <input
                                type='radio'
                                name={`correct-${qIndex}`}
                                checked={option.isCorrect}
                                onChange={() => updateOption(qIndex, oIndex, 'isCorrect', true)}
                                className='mr-2'
                              />
                              Correct
                            </label>
                          </div>
                        ))}
                        <button
                          type='button'
                          onClick={() => addOption(qIndex)}
                          className='text-primary-600 hover:text-primary-700 text-sm'
                        >
                          + Add Option
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className='flex justify-end mt-6'>
            <button
              type='submit'
              disabled={loading || isUploading}
              className={`px-4 py-2 rounded-md ${
                loading || isUploading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-primary-600 hover:bg-primary-700'
              } text-white`}
            >
              {loading
                ? 'Saving...'
                : isUploading
                  ? 'Video Uploading...'
                  : course
                    ? 'Save Course'
                    : 'Create Course'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CourseForm.propTypes = {
  course: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        contentType: PropTypes.string,
        content: PropTypes.string,
        duration: PropTypes.number,
        order: PropTypes.number,
      })
    ),
    status: PropTypes.string,
    userGroups: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.string,
    hasQuiz: PropTypes.bool,
    quiz: PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
              isCorrect: PropTypes.bool,
            })
          ),
        })
      ),
      passingScore: PropTypes.number,
      enabled: PropTypes.bool,
    }),
  }),
};

CourseForm.defaultProps = {
  course: null,
};

export default CourseForm;
