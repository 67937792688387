import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { FaGripVertical, FaInfoCircle, FaYoutube, FaUpload } from 'react-icons/fa';
import { Editor } from '@tinymce/tinymce-react';
import { courseApi } from '../../api/courseApi';
import FormLabel from '../Common/FormLabel';

const ModuleEditor = ({ module, index, onUpdate, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [videoType, setVideoType] = useState(module.videoType || 'youtube');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  // Migrate old content structure to new structure on first render
  useEffect(() => {
    const migrateContent = () => {
      if (
        module.content &&
        module.contentType &&
        !module.wysiwygContent &&
        !module.videoContent &&
        !module.documentContent
      ) {
        const updatedModule = {
          ...module,
          wysiwygContent: module.contentType === 'wysiwyg' ? module.content : undefined,
          videoContent: module.contentType === 'video' ? module.content : undefined,
          documentContent: module.contentType === 'document' ? module.content : undefined,
        };
        onUpdate(updatedModule);
      }
    };

    migrateContent();
  }, [module, onUpdate]);

  const handleVideoTypeChange = function (type) {
    setVideoType(type);
    const updatedModule = {
      ...module,
      videoType: type,
      videoContent: '', // Reset video content when changing type
    };
    onUpdate(updatedModule);
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('video/')) {
      alert('Please select a valid video file');
      event.target.value = '';
      return;
    }

    if (file.size > 500 * 1024 * 1024) {
      alert('File size must be less than 500MB');
      event.target.value = '';
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    onUpdate(module, true);

    try {
      const formData = new FormData();
      formData.append('video', file);

      const response = await courseApi.uploadVideo(formData, progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(Math.round(progress));
      });

      const updatedModule = {
        ...module,
        videoContent: response.data.videoUrl,
      };

      onUpdate(updatedModule, false);
    } catch (error) {
      console.error('Error uploading video:', error);
      alert('Failed to upload video');
      onUpdate(module, false);
    } finally {
      setUploading(false);
      setUploadProgress(0);
      event.target.value = '';
    }
  };

  return (
    <Draggable draggableId={module._id?.toString() || `new-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='bg-white border border-gray-200 rounded-lg overflow-hidden'
        >
          <div className='p-4 flex items-center justify-between bg-gray-50'>
            <div className='flex items-center gap-3'>
              <div {...provided.dragHandleProps} className='text-gray-400'>
                <FaGripVertical />
              </div>
              <h4 className='font-medium text-gray-900'>Module {index + 1}</h4>
            </div>
            <div className='flex items-center gap-2'>
              <button
                type='button'
                onClick={() => setIsExpanded(!isExpanded)}
                className='text-sm text-gray-600 hover:text-gray-900'
              >
                {isExpanded ? 'Collapse' : 'Expand'}
              </button>
              <button
                type='button'
                onClick={onDelete}
                className='text-sm text-red-600 hover:text-red-700'
              >
                Delete
              </button>
            </div>
          </div>

          {isExpanded && (
            <div className='p-4 space-y-6'>
              <div>
                <FormLabel htmlFor={`title-${index}`}>Title</FormLabel>
                <input
                  id={`title-${index}`}
                  type='text'
                  value={module.title || ''}
                  onChange={e => onUpdate({ ...module, title: e.target.value })}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                />
              </div>

              <div>
                <FormLabel htmlFor={`description-${index}`}>Description</FormLabel>
                <textarea
                  id={`description-${index}`}
                  value={module.description || ''}
                  onChange={e => onUpdate({ ...module, description: e.target.value })}
                  rows={3}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                />
              </div>

              {/* Video Content Section */}
              <div className='border-t pt-4'>
                <FormLabel>Video Content</FormLabel>
                <div className='flex gap-2 mb-4'>
                  <button
                    type='button'
                    onClick={() => handleVideoTypeChange('youtube')}
                    className={`flex items-center px-4 py-2 rounded-md ${
                      videoType === 'youtube'
                        ? 'bg-primary-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    <FaYoutube className='mr-2' /> YouTube Video
                  </button>
                  <button
                    type='button'
                    onClick={() => handleVideoTypeChange('uploaded')}
                    className={`flex items-center px-4 py-2 rounded-md ${
                      videoType === 'uploaded'
                        ? 'bg-primary-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    <FaUpload className='mr-2' /> Upload Video
                  </button>
                </div>

                {videoType === 'youtube' ? (
                  <div>
                    <FormLabel htmlFor={`youtubeUrl-${index}`}>YouTube Video URL</FormLabel>
                    <div className='mt-1 text-sm text-gray-500 flex items-center mb-2'>
                      <FaInfoCircle className='mr-1' />
                      Paste the full YouTube video URL
                    </div>
                    <input
                      id={`youtubeUrl-${index}`}
                      type='text'
                      value={module.videoContent || ''}
                      onChange={e => onUpdate({ ...module, videoContent: e.target.value })}
                      placeholder='https://www.youtube.com/watch?v=...'
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                    />
                  </div>
                ) : (
                  <div>
                    <FormLabel htmlFor={`uploadVideo-${index}`}>Upload Video</FormLabel>
                    <div className='mt-1 text-sm text-gray-500 flex items-center mb-2'>
                      <FaInfoCircle className='mr-1' />
                      Maximum file size: 500MB. Supported formats: MP4, WebM
                    </div>
                    <input
                      id={`uploadVideo-${index}`}
                      type='file'
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      accept='video/*'
                      className='hidden'
                      value=''
                    />
                    <button
                      type='button'
                      onClick={() => fileInputRef.current?.click()}
                      disabled={uploading}
                      className='flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200'
                    >
                      <FaUpload className='mr-2' />
                      {uploading ? `Uploading... ${uploadProgress}%` : 'Select Video'}
                    </button>
                    {module.videoContent && (
                      <div className='mt-2 text-sm text-gray-500'>
                        Current video: {module.videoContent}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Google Doc Content Section */}
              <div className='border-t pt-4'>
                <FormLabel>Google Doc Content</FormLabel>
                <div>
                  <FormLabel htmlFor={`documentUrl-${index}`}>Document URL</FormLabel>
                  <div className='mt-1 text-sm text-gray-500 flex items-center mb-2'>
                    <FaInfoCircle className='mr-1' />
                    Please ensure the document is published to the web and shared with
                    {' "Anyone with the link can view"'}
                  </div>
                  <input
                    id={`documentUrl-${index}`}
                    type='text'
                    value={module.documentContent || ''}
                    onChange={e => onUpdate({ ...module, documentContent: e.target.value })}
                    placeholder='https://docs.google.com/...'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                  />
                </div>
              </div>

              {/* WYSIWYG Content Section */}
              <div className='border-t pt-4'>
                <FormLabel>Rich Text Content</FormLabel>
                <Editor
                  apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                  value={module.wysiwygContent || ''}
                  init={{
                    height: 400,
                    menubar: true,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'wordcount',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                  }}
                  onEditorChange={content => onUpdate({ ...module, wysiwygContent: content })}
                />
              </div>

              <div>
                <FormLabel htmlFor={`duration-${index}`}>Duration (minutes)</FormLabel>
                <input
                  id={`duration-${index}`}
                  type='number'
                  value={module.duration || 30}
                  onChange={e => onUpdate({ ...module, duration: parseInt(e.target.value) })}
                  min='1'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500'
                />
              </div>
            </div>
          )}
          {uploading && (
            <div className='mt-2 px-4 pb-4'>
              <div className='text-sm text-gray-600'>Uploading... {uploadProgress}%</div>
              <div className='w-full bg-gray-200 rounded-full h-2.5'>
                <div
                  className='bg-primary-600 h-2.5 rounded-full'
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

ModuleEditor.propTypes = {
  module: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    contentType: PropTypes.string,
    videoType: PropTypes.string,
    videoContent: PropTypes.string,
    documentContent: PropTypes.string,
    wysiwygContent: PropTypes.string,
    duration: PropTypes.number,
  }),
  index: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ModuleEditor;
