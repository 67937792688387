import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress, height = 20, showLabel = true }) => {
  return (
    <div className='progress-container'>
      <div className='progress-bar' style={{ height: `${height}px` }}>
        <div className='progress-fill' style={{ width: `${progress}%` }} />
      </div>
      {showLabel && <span className='progress-label'>{Math.round(progress)}%</span>}
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  height: PropTypes.number,
  showLabel: PropTypes.bool,
};

ProgressBar.defaultProps = {
  height: 20,
  showLabel: true,
};

export default ProgressBar;
