import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const CourseFormContext = createContext();

export const CourseFormProvider = ({ children }) => {
  return <CourseFormContext.Provider value={{}}>{children}</CourseFormContext.Provider>;
};

CourseFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CourseFormContext;
