import React from 'react';
import PropTypes from 'prop-types';

const FormLabel = ({ htmlFor, required, children }) => {
  return (
    <label htmlFor={htmlFor} className='block text-sm font-medium text-gray-700 mb-1'>
      {children}
      {required && <span className='text-red-500 ml-1'>*</span>}
    </label>
  );
};

FormLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FormLabel.defaultProps = {
  required: false,
};

export default FormLabel;
