import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import './WysiwygContent.css';

const WysiwygContent = ({ content }) => {
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div className='wysiwyg-content prose prose-lg max-w-none'>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </div>
  );
};

WysiwygContent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default WysiwygContent;
