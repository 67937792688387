import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaQuestion } from 'react-icons/fa';
import VideoPlayer from './VideoPlayer';
import MessageModal from '../Messaging/MessageModal';
import WysiwygContent from './WysiwygContent';
import QuizComponent from './QuizComponent';

const ModuleContent = ({ module, onComplete, isCompleted, courseName }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleComplete = async () => {
    if (onComplete && !isCompleting && isConfirmed) {
      try {
        setIsCompleting(true);
        await onComplete();
      } catch (error) {
        console.error('Error completing module:', error);
      } finally {
        setIsCompleting(false);
      }
    }
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isFirstPlay) {
        videoRef.current.currentTime = 0;
        setIsFirstPlay(false);
      }
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlay = () => {
    if (isFirstPlay) {
      videoRef.current.currentTime = 0;
      setIsFirstPlay(false);
    }
    setIsPlaying(true);
  };

  const generateThumbnail = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      video.addEventListener('loadeddata', () => {
        video.currentTime = 2;
      });
      video.addEventListener('seeked', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailUrl = canvas.toDataURL('image/jpeg');
        setThumbnailUrl(thumbnailUrl);
      });
    }
  };

  const renderVideoContent = () => {
    const videoContent =
      module.videoContent || (module.contentType === 'video' ? module.content : null);
    if (!videoContent) return null;

    if (videoContent.includes('youtube.com') || videoContent.includes('youtu.be')) {
      return (
        <div className='aspect-w-16 aspect-h-9 mb-6'>
          <VideoPlayer url={videoContent} onComplete={handleComplete} />
        </div>
      );
    } else {
      return (
        <div className='aspect-w-16 aspect-h-9 mb-6 relative group'>
          <canvas ref={canvasRef} className='hidden' />
          <video
            ref={videoRef}
            className='w-full h-full rounded-lg'
            onEnded={handleComplete}
            src={videoContent}
            poster={thumbnailUrl}
            preload='metadata'
            controls
            playsInline
            onPlay={handlePlay}
            onPause={handlePause}
            onLoadedMetadata={generateThumbnail}
          >
            <track kind='captions' srcLang='en' label='English Captions' />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <button
              onClick={handlePlayClick}
              className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 group-hover:bg-opacity-20 transition-all focus:outline-none focus:ring-2 focus:ring-primary-500'
              aria-label='Play video'
            >
              <div className='w-20 h-20 bg-black bg-opacity-60 rounded-full flex items-center justify-center'>
                <svg className='w-12 h-12 text-white' fill='currentColor' viewBox='0 0 20 20'>
                  <path d='M6.3 2.841A1.5 1.5 0 004 4.11v11.78a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z' />
                </svg>
              </div>
            </button>
          )}
        </div>
      );
    }
  };

  const renderDocumentContent = () => {
    const documentContent =
      module.documentContent || (module.contentType === 'document' ? module.content : null);
    if (!documentContent) return null;

    // Transform Google Doc/Slides URL to proper embed URL
    const getEmbedUrl = function (url) {
      // Check if it's a Google Doc or Slides URL
      if (url.includes('docs.google.com')) {
        const isPresentation = url.includes('/presentation/d/');
        const docId = url.match(/\/d\/([a-zA-Z0-9-_]+)/)?.[1];

        if (!docId) return url;

        if (isPresentation) {
          return `https://docs.google.com/presentation/d/${docId}/embed?start=false&loop=false&delayms=3000`;
        }
        return `https://docs.google.com/document/d/${docId}/preview`;
      }
      return url;
    };

    const embedUrl = getEmbedUrl(documentContent);
    const isPresentation = documentContent.includes('/presentation/d/');
    const title = isPresentation ? 'Google Slides' : 'Google Document';

    return (
      <div className='mb-6'>
        <div className='aspect-w-16 aspect-h-9'>
          <iframe
            src={embedUrl}
            title={title}
            className='w-full h-full rounded-lg border-0'
            allowFullScreen
          />
        </div>
      </div>
    );
  };

  const renderWysiwygContent = () => {
    const wysiwygContent =
      // eslint-disable-next-line react/prop-types
      module.wysiwygContent || (module.contentType === 'wysiwyg' ? module.content : null);
    if (!wysiwygContent) return null;

    return (
      <div className='bg-white rounded-lg shadow-sm overflow-hidden mb-6'>
        <div className='p-6'>
          <WysiwygContent content={wysiwygContent} />
        </div>
      </div>
    );
  };

  return (
    <div className='relative'>
      <div className='flex justify-between items-center mb-6'>
        <h2 className='text-2xl font-bold text-gray-900'>{module.title}</h2>
        {isCompleted && (
          <span className='flex items-center text-green-600'>
            <FaCheckCircle className='mr-2' /> Completed
          </span>
        )}
      </div>

      <div className='prose max-w-none mb-8'>
        <p className='text-gray-600'>{module.description}</p>
      </div>

      {renderVideoContent()}
      {renderDocumentContent()}
      {renderWysiwygContent()}

      {module.quiz && !isCompleted && (
        <div className='mt-8 pt-8 border-t border-gray-200'>
          <h3 className='text-xl font-semibold mb-4'>Module Quiz</h3>
          <QuizComponent quiz={module.quiz} onComplete={handleComplete} />
        </div>
      )}

      <div className='flex flex-col gap-4 mt-6'>
        {!isCompleted && (
          <div className='flex items-center'>
            <input
              type='checkbox'
              id='confirmCheckbox'
              checked={isConfirmed}
              onChange={e => setIsConfirmed(e.target.checked)}
              className='h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded'
            />
            <label htmlFor='confirmCheckbox' className='ml-2 text-sm text-gray-600'>
              I have reviewed and understand this module material.
            </label>
          </div>
        )}

        <div className='flex justify-between items-center'>
          <button
            onClick={() => setShowMessageModal(true)}
            className='px-4 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-md flex items-center'
          >
            <FaQuestion className='mr-2' /> Ask A Question
          </button>

          {!isCompleted && (
            <button
              onClick={handleComplete}
              disabled={!isConfirmed || isCompleting}
              className={`px-4 py-2 rounded-md text-white flex items-center ${
                !isConfirmed || isCompleting
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-primary-600 hover:bg-primary-700'
              }`}
            >
              {isCompleting ? (
                <>
                  <svg
                    className='animate-spin -ml-1 mr-2 h-4 w-4 text-white'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                  Completing...
                </>
              ) : (
                'Complete Module'
              )}
            </button>
          )}
        </div>
      </div>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          onClose={() => setShowMessageModal(false)}
          defaultSubject={`Question about ${courseName} / ${module.title}`}
          readOnlySubject={true}
          recipientRole='admin'
          metadata={{
            type: 'module_question',
            moduleId: module._id,
            moduleName: module.title,
          }}
        />
      )}
    </div>
  );
};

ModuleContent.propTypes = {
  module: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    content: PropTypes.string,
    contentType: PropTypes.string,
    videoContent: PropTypes.string,
    documentContent: PropTypes.string,
    wysiwygContent: PropTypes.string,
    quiz: PropTypes.object,
  }).isRequired,
  onComplete: PropTypes.func,
  isCompleted: PropTypes.bool,
  courseName: PropTypes.string.isRequired,
};

export default ModuleContent;
