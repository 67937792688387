import api from './axiosConfig';

export const messageApi = {
  getThreads: (params = {}) => api.get('/messages', { params }),

  getThread: threadId => api.get(`/messages/${threadId}`),

  createThread: data =>
    api.post('/messages', {
      recipient: data.recipient,
      subject: data.subject,
      message: data.message,
    }),

  replyToMessage: (threadId, data) => api.post(`/messages/${threadId}/reply`, data),

  markAsRead: threadId => api.post(`/messages/${threadId}/read`),

  archiveThread: threadId => api.post(`/messages/${threadId}/archive`),

  deleteThread: threadId => api.delete(`/messages/${threadId}`),

  getUnreadCount: () => api.get('/messages/unread-count'),
};
