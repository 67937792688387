import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { messageApi } from '../../api/messageApi';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';
import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaPencilAlt,
  FaChevronDown,
  FaChevronRight,
  FaArchive,
} from 'react-icons/fa';
import NewThreadModal from './NewThreadModal';
import PropTypes from 'prop-types';

const MessageThread = ({ thread }) => {
  const lastMessage = thread.messages[thread.messages.length - 1];
  const isRead = thread.readStatus?.some(
    status => status.lastReadMessageId?.toString() === lastMessage?._id?.toString()
  );

  return (
    <Link to={`/messages/${thread._id}`} className={`message-thread ${!isRead ? 'unread' : ''}`}>
      <div className='p-6'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            {isRead ? (
              <FaEnvelopeOpen className='text-gray-400 mr-3' />
            ) : (
              <FaEnvelope className='text-primary-600 mr-3' />
            )}
            <div>
              <h3 className='text-sm font-medium text-gray-900'>{thread.title}</h3>
              <p className='text-sm text-gray-500'>
                {lastMessage ? lastMessage.content : 'No messages'}
              </p>
            </div>
          </div>
          <div className='text-sm text-gray-500'>
            {format(new Date(thread.lastMessageAt), 'MMM d, yyyy')}
          </div>
        </div>
      </div>
    </Link>
  );
};

MessageThread.propTypes = {
  thread: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
    readStatus: PropTypes.arrayOf(
      PropTypes.shape({
        lastReadMessageId: PropTypes.string,
      })
    ),
    lastMessageAt: PropTypes.string.isRequired,
  }).isRequired,
};

const MessageList = () => {
  const [threads, setThreads] = useState([]);
  const [archivedThreads, setArchivedThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showNewThreadModal, setShowNewThreadModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchThreads();
  }, []);

  const fetchThreads = async () => {
    try {
      const [activeResponse, archivedResponse] = await Promise.all([
        messageApi.getThreads({ archived: false }),
        messageApi.getThreads({ archived: true }),
      ]);

      setThreads(activeResponse.data);
      setArchivedThreads(archivedResponse.data);
    } catch (err) {
      setError('Failed to load messages');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewMessageSuccess = () => {
    setShowNewThreadModal(false);
    fetchThreads();
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center min-h-[400px]'>
        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='bg-white rounded-lg shadow-sm'>
        <div className='px-6 py-4 border-b border-gray-200 flex justify-between items-center'>
          <h2 className='text-xl font-semibold text-gray-900'>Messages</h2>
          {user.role === 'admin' && (
            <button
              onClick={() => setShowNewThreadModal(true)}
              className='flex items-center px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700'
            >
              <FaPencilAlt className='mr-2' /> New Message
            </button>
          )}
        </div>

        {error && <div className='p-4 text-red-600 bg-red-50'>{error}</div>}

        <div className='divide-y divide-gray-200'>
          {/* Active Messages */}
          {threads.length > 0 ? (
            threads.map(thread => <MessageThread key={thread._id} thread={thread} />)
          ) : (
            <div className='p-6 text-center text-gray-500'>No active messages</div>
          )}

          {/* Archived Messages Section */}
          <div className='border-t border-gray-200'>
            <button
              onClick={() => setShowArchived(!showArchived)}
              className='w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50'
            >
              <div className='flex items-center'>
                <FaArchive className='text-gray-400 mr-2' />
                <span className='text-sm font-medium text-gray-700'>
                  Archived Messages ({archivedThreads.length})
                </span>
              </div>
              {showArchived ? (
                <FaChevronDown className='text-gray-400' />
              ) : (
                <FaChevronRight className='text-gray-400' />
              )}
            </button>
            {showArchived && (
              <div className='bg-gray-50'>
                {archivedThreads.length > 0 ? (
                  archivedThreads.map(thread => <MessageThread key={thread._id} thread={thread} />)
                ) : (
                  <div className='p-6 text-center text-gray-500'>No archived messages</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showNewThreadModal && user.role === 'admin' && (
        <NewThreadModal
          isOpen={showNewThreadModal}
          onClose={() => setShowNewThreadModal(false)}
          onSuccess={handleNewMessageSuccess}
        />
      )}
    </div>
  );
};

export default MessageList;
