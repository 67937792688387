import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import { useNotifications } from '../../contexts/NotificationContext';
import { format } from 'date-fns';

const NotificationCenter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { notifications, unreadCount, markAsRead } = useNotifications();

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleNotificationClick = async notification => {
    try {
      if (notification.status === 'unread') {
        await markAsRead(notification._id);
      }
      setIsOpen(false);
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  };

  return (
    <div className='relative' ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='relative p-2 text-gray-600 hover:text-gray-900 focus:outline-none'
      >
        <FaBell className='h-6 w-6' />
        {unreadCount > 0 && (
          <span className='absolute -top-2 -right-2 flex items-center justify-center min-w-[20px] h-5 px-1.5 text-xs font-bold text-white bg-red-600 rounded-full'>
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className='absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-50'>
          <div className='p-4 border-b border-gray-200'>
            <h3 className='text-lg font-semibold text-gray-900'>Notifications</h3>
          </div>
          <div className='max-h-96 overflow-y-auto'>
            {notifications.length === 0 ? (
              <div className='p-4 text-center text-gray-500'>No notifications</div>
            ) : (
              notifications.map(notification => {
                const messageId = notification.data?.messageId?._id || notification.data?.messageId;

                return (
                  <Link
                    key={notification._id}
                    to={messageId ? `/messages/${messageId}` : '/messages'}
                    onClick={() => handleNotificationClick(notification)}
                    className={`block w-full text-left p-4 hover:bg-gray-50 border-b border-gray-200 transition-colors duration-150 group ${
                      notification.status === 'unread' ? 'bg-blue-50' : ''
                    }`}
                  >
                    <div className='flex flex-col'>
                      <span className='font-medium text-gray-900 group-hover:text-primary-600'>
                        {notification.title}
                      </span>
                      <span className='text-sm text-gray-600 group-hover:text-primary-500'>
                        {notification.message}
                      </span>
                      <span className='text-xs text-gray-500 mt-1'>
                        {format(new Date(notification.createdAt), 'MMM d, yyyy h:mm a')}
                      </span>
                    </div>
                  </Link>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationCenter;
