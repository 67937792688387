import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { courseApi } from '../../api/courseApi';
import { FaPlus, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Common/ConfirmationModal';
import { userGroupLabels } from '../../utils/constants';
import FormLabel from '../Common/FormLabel';

const CoursesPage = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);

  // Filter states
  const [filters, setFilters] = useState({
    status: 'all',
    userGroup: 'all',
    search: '',
  });

  const filterCourses = useCallback(() => {
    let filtered = [...courses];

    // Filter by status
    if (filters.status !== 'all') {
      filtered = filtered.filter(course => course.status === filters.status);
    }

    // Filter by user group
    if (filters.userGroup !== 'all') {
      filtered = filtered.filter(course => course.userGroups.includes(filters.userGroup));
    }

    // Filter by search term
    if (filters.search) {
      const searchTerm = filters.search.toLowerCase();
      filtered = filtered.filter(
        course =>
          course.title.toLowerCase().includes(searchTerm) ||
          course.description.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredCourses(filtered);
  }, [courses, filters]);

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    filterCourses();
  }, [filterCourses]);

  const fetchCourses = async () => {
    try {
      const response = await courseApi.getAllCourses();
      setCourses(response.data);
    } catch (err) {
      setError('Failed to load courses');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteClick = course => {
    setCourseToDelete(course);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await courseApi.deleteCourse(courseToDelete._id);
      toast.success('Course deleted successfully');
      fetchCourses();
    } catch (err) {
      toast.error('Failed to delete course');
      console.error(err);
    } finally {
      setShowDeleteModal(false);
      setCourseToDelete(null);
    }
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center min-h-[400px]'>
        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='flex justify-between items-center mb-8'>
        <h1 className='text-2xl font-bold text-gray-900'>Manage Courses</h1>
        <Link
          to='/admin/courses/new'
          className='flex items-center px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700'
        >
          <FaPlus className='mr-2' /> Create Course
        </Link>
      </div>

      {/* Filters Section */}
      <div className='bg-white p-4 rounded-lg shadow-sm mb-6'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {/* Search Filter */}
          <div>
            <FormLabel htmlFor='search'>Search</FormLabel>
            <input
              id='search'
              type='text'
              value={filters.search}
              onChange={e => handleFilterChange('search', e.target.value)}
              placeholder='Search courses...'
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
            />
          </div>

          {/* Status Filter */}
          <div>
            <FormLabel htmlFor='status'>Status</FormLabel>
            <select
              id='status'
              value={filters.status}
              onChange={e => handleFilterChange('status', e.target.value)}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
            >
              <option value='all'>All Statuses</option>
              <option value='draft'>Draft</option>
              <option value='published'>Published</option>
            </select>
          </div>

          {/* User Group Filter */}
          <div>
            <FormLabel htmlFor='userGroup'>User Group</FormLabel>
            <select
              id='userGroup'
              value={filters.userGroup}
              onChange={e => handleFilterChange('userGroup', e.target.value)}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
            >
              <option value='all'>All Groups</option>
              {Object.entries(userGroupLabels).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {error && <div className='mb-4 text-red-600 bg-red-50 p-4 rounded-md'>{error}</div>}

      <div className='bg-white shadow-sm rounded-lg overflow-hidden'>
        <div className='overflow-x-auto relative'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                  Title
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                  Status
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                  Available To
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                  Created By
                </th>
                <th className='sticky right-0 z-10 bg-gray-50 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {filteredCourses.map(course => (
                <tr key={course._id}>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    <div className='text-sm font-medium text-gray-900'>{course.title}</div>
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap'>
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        course.status === 'published'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {course.status}
                    </span>
                  </td>
                  <td className='px-6 py-4'>
                    <div className='text-sm text-gray-500'>
                      {course.userGroups?.map(group => (
                        <span
                          key={group}
                          className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2 mb-1'
                        >
                          {userGroupLabels[group]}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                    {course.createdBy?.firstName} {course.createdBy?.lastName}
                  </td>
                  <td className='sticky right-0 z-10 px-6 py-4 whitespace-nowrap text-sm font-medium'>
                    <div className='flex justify-end items-center md:bg-transparent bg-white md:shadow-none shadow-md md:rounded-none rounded-l-md pl-4'>
                      <button
                        onClick={() => navigate(`/admin/courses/${course._id}/edit`)}
                        className='text-primary-600 hover:text-primary-900 p-2'
                        title='Edit Course'
                      >
                        <FaPencilAlt />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(course)}
                        className='text-red-600 hover:text-red-900 p-2 ml-2'
                        title='Delete Course'
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setCourseToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        title='Delete Course'
        message={`Are you sure you want to delete "${courseToDelete?.title}"? This action cannot be undone.`}
      />
    </div>
  );
};

export default CoursesPage;
