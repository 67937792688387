import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { courseApi } from '../../api/courseApi';
import { FaBook, FaCheckCircle, FaChartLine, FaPlay } from 'react-icons/fa';

const UserDashboard = () => {
  const [courses, setCourses] = useState({
    available: [],
    inProgress: [],
    completed: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await courseApi.getDashboardCourses();
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to load courses');
    } finally {
      setLoading(false);
    }
  };

  // Calculate stats
  const completedCourses = courses.completed?.length || 0;
  const inProgressCourses = courses.inProgress?.length || 0;
  const availableCourses = courses.available?.length || 0;
  const totalCourses = completedCourses + inProgressCourses + availableCourses;

  // Calculate total progress
  const totalProgress =
    totalCourses > 0
      ? Math.round(
          (completedCourses * 100 +
            (courses.inProgress?.reduce((sum, course) => sum + (course.progress || 0), 0) || 0)) /
            totalCourses
        )
      : 0;

  if (loading) {
    return (
      <div className='flex justify-center items-center min-h-[400px]'>
        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      {/* Stats Section */}
      <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
        <div className='bg-white p-6 rounded-lg shadow-sm'>
          <div className='flex items-center'>
            <div className='p-3 rounded-full bg-primary-100 text-primary-600'>
              <FaBook className='h-6 w-6' />
            </div>
            <div className='ml-4'>
              <p className='text-sm font-medium text-gray-500'>Total Courses</p>
              <p className='text-2xl font-semibold text-gray-900'>{totalCourses}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-lg shadow-sm'>
          <div className='flex items-center'>
            <div className='p-3 rounded-full bg-green-100 text-green-600'>
              <FaCheckCircle className='h-6 w-6' />
            </div>
            <div className='ml-4'>
              <p className='text-sm font-medium text-gray-500'>Completed</p>
              <p className='text-2xl font-semibold text-gray-900'>{completedCourses}</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-lg shadow-sm'>
          <div className='flex items-center'>
            <div className='p-3 rounded-full bg-blue-100 text-blue-600'>
              <FaChartLine className='h-6 w-6' />
            </div>
            <div className='ml-4'>
              <p className='text-sm font-medium text-gray-500'>In Progress</p>
              <p className='text-2xl font-semibold text-gray-900'>{inProgressCourses}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Overall Progress */}
      <div className='bg-white rounded-lg shadow-sm p-6 mb-8'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-lg font-semibold text-gray-900'>Overall Progress</h2>
          <span className='text-2xl font-bold text-primary-600'>{totalProgress}%</span>
        </div>
        <div className='w-full bg-gray-200 rounded-full h-2.5'>
          <div
            className='bg-primary-600 h-2.5 rounded-full transition-all duration-500'
            style={{ width: `${totalProgress}%` }}
          ></div>
        </div>
      </div>

      {error && <div className='bg-red-50 text-red-600 p-4 rounded-lg mb-8'>{error}</div>}

      {/* Available Courses */}
      <div className='mb-12'>
        <h2 className='text-xl font-semibold text-gray-900 mb-6'>Available Courses</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {courses.available.length === 0 ? (
            <p className='text-gray-500 col-span-full'>No available courses.</p>
          ) : (
            courses.available.map(course => (
              <Link
                key={course._id}
                to={`/course/${course._id}`}
                className='bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300'
              >
                {course.thumbnail && (
                  <div className='h-48 overflow-hidden'>
                    <img
                      src={course.thumbnail}
                      alt={course.title}
                      className='w-full h-full object-cover'
                    />
                  </div>
                )}
                <div className='p-6'>
                  <h3 className='text-lg font-semibold text-gray-900 mb-2'>{course.title}</h3>
                  <p className='text-sm text-gray-600 mb-4 line-clamp-2'>{course.description}</p>
                  <button className='w-full flex items-center justify-center px-4 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors'>
                    <FaBook className='mr-2 h-4 w-4' />
                    Start Course
                  </button>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>

      {/* In Progress Courses */}
      <div className='mb-12'>
        <h2 className='text-xl font-semibold text-gray-900 mb-6'>In Progress</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {courses.inProgress.length === 0 ? (
            <p className='text-gray-500 col-span-full'>No courses in progress.</p>
          ) : (
            courses.inProgress.map(course => (
              <Link
                key={course._id}
                to={`/course/${course._id}`}
                className='bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300'
              >
                {course.thumbnail && (
                  <div className='h-48 overflow-hidden'>
                    <img
                      src={course.thumbnail}
                      alt={course.title}
                      className='w-full h-full object-cover'
                    />
                  </div>
                )}
                <div className='p-6'>
                  <h3 className='text-lg font-semibold text-gray-900 mb-2'>{course.title}</h3>
                  <p className='text-sm text-gray-600 mb-4 line-clamp-2'>{course.description}</p>
                  <div className='space-y-4'>
                    <div className='flex items-center'>
                      <div className='w-full bg-gray-200 rounded-full h-2'>
                        <div
                          className='bg-primary-600 h-2 rounded-full transition-all duration-500'
                          style={{ width: `${course.progress || 0}%` }}
                        ></div>
                      </div>
                      <span className='ml-2 text-sm text-gray-600'>{course.progress || 0}%</span>
                    </div>
                    <button className='w-full flex items-center justify-center px-4 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors'>
                      <FaPlay className='mr-2 h-4 w-4' />
                      Continue Course
                    </button>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>

      {/* Completed Courses */}
      <div className='mb-12'>
        <h2 className='text-xl font-semibold text-gray-900 mb-6'>Completed</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {courses.completed.length === 0 ? (
            <p className='text-gray-500 col-span-full'>No completed courses.</p>
          ) : (
            courses.completed.map(course => (
              <Link
                key={course._id}
                to={`/course/${course._id}`}
                className='bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300'
              >
                {course.thumbnail && (
                  <div className='h-48 overflow-hidden'>
                    <img
                      src={course.thumbnail}
                      alt={course.title}
                      className='w-full h-full object-cover'
                    />
                  </div>
                )}
                <div className='p-6'>
                  <h3 className='text-lg font-semibold text-gray-900 mb-2'>{course.title}</h3>
                  <p className='text-sm text-gray-600 mb-4 line-clamp-2'>{course.description}</p>
                  <div className='space-y-4'>
                    <div className='flex items-center'>
                      <div className='w-full bg-green-100 rounded-full h-2'>
                        <div
                          className='bg-green-500 h-2 rounded-full'
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                      <span className='ml-2 text-sm text-green-600'>100%</span>
                    </div>
                    <button className='w-full flex items-center justify-center px-4 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors'>
                      <FaCheckCircle className='mr-2 h-4 w-4' />
                      View Course
                    </button>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
