import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@mui/material';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className='modal-content'>
        <h2>{title}</h2>
        <p>{message}</p>
        <div className='modal-actions'>
          <Button onClick={onClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant='contained' color='primary'>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ConfirmationModal;
