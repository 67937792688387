import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { messageApi } from '../../api/messageApi';
import { format } from 'date-fns';
import { FaArrowLeft, FaArchive, FaTrash } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import ConfirmDeleteModal from '../Common/ConfirmDeleteModal';

const POLLING_INTERVAL = 5000; // 5 seconds

const MessageThread = () => {
  const { threadId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [thread, setThread] = useState(null);
  const [reply, setReply] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [error, setError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const messagesEndRef = useRef(null);
  const pollingTimeoutRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchThread = useCallback(async () => {
    try {
      const response = await messageApi.getThread(threadId);
      setThread(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to load message thread');
      setLoading(false);
    }
  }, [threadId]);

  // Set up polling for thread updates
  useEffect(() => {
    const pollThread = async () => {
      try {
        const response = await messageApi.getThread(threadId);
        const newThread = response.data;

        // Only update if there are new messages
        if (newThread.messages.length !== thread?.messages.length) {
          setThread(newThread);
          scrollToBottom();
        }
      } catch (error) {
        console.error('Error polling thread:', error);
      }

      // Schedule next poll
      pollingTimeoutRef.current = setTimeout(pollThread, POLLING_INTERVAL);
    };

    if (thread && !thread.archived) {
      pollingTimeoutRef.current = setTimeout(pollThread, POLLING_INTERVAL);
    }

    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, [threadId, thread]);

  useEffect(() => {
    fetchThread();
  }, [fetchThread]);

  // Scroll to bottom when thread loads or messages update
  useEffect(() => {
    scrollToBottom();
  }, [thread?.messages]);

  const handleReply = async e => {
    e.preventDefault();
    if (!reply.trim()) return;

    try {
      setSending(true);
      const response = await messageApi.replyToMessage(threadId, { content: reply });

      // Immediately update the thread with the new message
      setThread(response.data);
      setReply('');
      scrollToBottom();
    } catch (err) {
      setError('Failed to send reply');
      console.error(err);
    } finally {
      setSending(false);
    }
  };

  const handleArchive = async () => {
    try {
      setArchiving(true);
      await messageApi.archiveThread(threadId);
      toast.success('Conversation closed successfully');
      navigate('/messages');
    } catch (err) {
      console.error('Error archiving thread:', err);
      toast.error('Failed to close conversation');
      setArchiving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await messageApi.deleteThread(threadId);
      toast.success('Conversation deleted successfully');
      navigate('/messages');
    } catch (err) {
      console.error('Error deleting thread:', err);
      toast.error('Failed to delete conversation');
    }
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center min-h-[400px]'>
        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600'></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
        <div className='bg-red-50 text-red-600 p-4 rounded-lg'>{error}</div>
      </div>
    );
  }

  if (!thread) {
    return (
      <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
        <div className='bg-yellow-50 text-yellow-600 p-4 rounded-lg'>Thread not found</div>
      </div>
    );
  }

  return (
    <div className='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='bg-white rounded-lg shadow-sm min-h-[calc(100vh-12rem)] flex flex-col'>
        <div className='px-6 py-4 border-b border-gray-200 flex justify-between items-center'>
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/messages')}
              className='mr-4 text-gray-400 hover:text-gray-600'
            >
              <FaArrowLeft />
            </button>
            <div>
              <h2 className='text-xl font-semibold text-gray-900'>{thread?.title}</h2>
              {thread?.status === 'archived' && (
                <span className='text-sm text-gray-500 flex items-center mt-1'>
                  <FaArchive className='mr-1' /> Archived conversation
                </span>
              )}
            </div>
          </div>
          {thread?.status === 'archived' && user.role === 'admin' && (
            <button
              onClick={() => setShowDeleteModal(true)}
              className='flex items-center px-4 py-2 rounded-md bg-red-100 hover:bg-red-200 text-red-700'
            >
              <FaTrash className='mr-2' />
              Delete Conversation
            </button>
          )}
          {thread?.status === 'active' && (
            <button
              onClick={handleArchive}
              disabled={archiving}
              className={`flex items-center px-4 py-2 rounded-md ${
                archiving
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
              }`}
            >
              <FaArchive className='mr-2' />
              {archiving ? 'Ending...' : 'End Conversation'}
            </button>
          )}
        </div>

        <div className='flex-1 p-6 space-y-6 overflow-y-auto'>
          {thread.messages.map((msg, index) => {
            const isCurrentUser = msg.sender?._id?.toString() === user?._id?.toString();
            return (
              <div
                key={index}
                className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[70%] rounded-lg p-4 ${
                    isCurrentUser ? 'bg-primary-100 text-primary-900' : 'bg-gray-100 text-gray-900'
                  }`}
                >
                  <div className='flex flex-col'>
                    <span
                      className={`text-sm font-medium mb-1 ${
                        isCurrentUser ? 'text-primary-800' : 'text-gray-800'
                      }`}
                    >
                      {msg.sender.firstName} {msg.sender.lastName}
                    </span>
                    <p className='text-sm whitespace-pre-wrap'>{msg.content}</p>
                    {msg.metadata && (
                      <div className='mt-2 text-xs text-gray-500'>
                        {msg.metadata.type === 'module_question' && (
                          <span>
                            From: {msg.metadata.courseName} / {msg.metadata.moduleName}
                          </span>
                        )}
                      </div>
                    )}
                    <span
                      className={`text-xs mt-2 ${
                        isCurrentUser ? 'text-primary-600' : 'text-gray-500'
                      }`}
                    >
                      {format(new Date(msg.createdAt), 'MMM d, yyyy h:mm a')}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        {thread?.status === 'active' && (
          <div className='p-6 border-t border-gray-200 mt-auto'>
            <form onSubmit={handleReply}>
              <div className='mb-4'>
                <textarea
                  value={reply}
                  onChange={e => setReply(e.target.value)}
                  rows={3}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
                  placeholder='Type your reply...'
                  disabled={sending}
                />
              </div>
              <div className='flex justify-end'>
                <button
                  type='submit'
                  disabled={sending || !reply.trim()}
                  className={`px-4 py-2 rounded-md text-white ${
                    sending || !reply.trim()
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-primary-600 hover:bg-primary-700'
                  }`}
                >
                  {sending ? 'Sending...' : 'Send Reply'}
                </button>
              </div>
            </form>
          </div>
        )}

        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          title='Delete Conversation'
          message='Are you sure you want to permanently delete this conversation? This action cannot be undone.'
        />
      </div>
    </div>
  );
};

export default MessageThread;
