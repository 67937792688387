import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { notificationApi } from '../api/notificationApi';
import { useAuth } from './AuthContext';
import PropTypes from 'prop-types';

const NotificationContext = createContext(null);

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useAuth();

  const fetchUnreadCount = useCallback(async () => {
    try {
      const response = await notificationApi.getUnreadCount();
      setUnreadCount(response.data.count);
    } catch (error) {
      console.error('Failed to fetch unread count:', error);
    }
  }, []);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await notificationApi.getNotifications();
      setNotifications(response.data);
      updateUnreadCount(response.data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchNotifications();
      fetchUnreadCount();

      // Set up polling for new notifications
      const interval = setInterval(() => {
        fetchNotifications();
        fetchUnreadCount();
      }, 30000); // Poll every 30 seconds

      return () => clearInterval(interval);
    }
  }, [user, fetchNotifications, fetchUnreadCount]);

  const markAsRead = async notificationId => {
    try {
      await notificationApi.markAsRead(notificationId);

      // Update local state
      setNotifications(prev =>
        prev.map(notification =>
          notification._id === notificationId
            ? { ...notification, status: 'read', readAt: new Date() }
            : notification
        )
      );

      // Update unread count
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const updateUnreadCount = notifications => {
    const count = notifications.filter(n => n.status === 'unread').length;
    setUnreadCount(count);
  };

  const value = {
    notifications,
    unreadCount,
    markAsRead,
    refreshNotifications: fetchNotifications,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationContext;
